.posx-barcode-label-container {
  border: 1px solid #d1d8dd;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
}

.posx-barcode-label {
  background-color: #ffffff;
  color: #000000;
  box-sizing: border-box;
  height: 34mm;
  width: 64mm;
  padding: 1.5mm;
  display: grid;
  grid-template-columns: 1fr 6mm;
  grid-template-rows: 4mm 1fr 17mm;
  grid-template-areas: "header footer" "text footer" "barcode footer";
  font-family: monospace;
  font-size: 8pt;
  line-height: 1.2;
  text-align: center;
}

.posx-barcode-rotated {
  line-height: 6mm;
  transform: translate(-12.5mm, 0) rotate(-90deg);
  transform-origin: center center;
  height: 6mm;
  width: 31mm;
}

.posx-barcode-header {
  grid-area: header;
  text-transform: uppercase;
}

.posx-barcode-text {
  grid-area: text;
  height: 28.8pt;
  overflow-y: hidden;
  align-self: center;
}

.posx-barcode-area {
  grid-area: barcode;
  align-self: center;
}

.posx-barcode-foot {
  grid-area: footer;
  font-size: 1.4em;
  align-self: center;
}

.posx-barcode-strong {
  font-weight: bold;
}